import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { TopItemProps, TopItem } from '../index'
import { SerializedAction } from 'Constants'
import TopStepsStore from './Store'
import StepItem from './StepItem'

interface TopStepsProps extends TopItemProps {
  route: string
  state: string
}

const TopSteps = observer(
  ({
    route,
    title,
    subTitle,
    linkPath,
    linkLabel,
    number = 3,
    emptyMessage,
    state
  }: TopStepsProps) => {
    const _isLoading = TopStepsStore.data[state].loading
    const _isEmpty = TopStepsStore.data[state].actions.length === 0

    useEffect(() => {
      void TopStepsStore.init({
        state,
        pageSize: number,
        route
      })
    }, [])

    return (
      <TopItem
        linkPath={linkPath}
        linkLabel={linkLabel}
        title={title}
        subTitle={subTitle}
        loading={_isLoading}
        emptyMessage={_isEmpty && emptyMessage}>
        {!_isLoading &&
          !_isEmpty &&
          TopStepsStore.data[state].actions.map(
            (item: SerializedAction, index: number) => {
              return (
                <StepItem
                  key={`top-step-${item.id}`}
                  item={item}
                  index={index}
                  state={state}
                />
              )
            }
          )}
      </TopItem>
    )
  }
)

export default TopSteps
