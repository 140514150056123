import I18n from 'i18n-js'
import { action, observable } from 'mobx'
import { API } from 'API'
import { standardizeError } from 'Utilities/errors'
import { TeamDataProps } from '../../index'

export interface ItemProps {
  id: string
  rank: string
  score: number
  name: string
  step: string
}

class TopTeamsStore {
  @observable public loading = true
  @observable items: ItemProps[] = []

  @action
  fetchItems = async ({ pageSize, route }) => {
    try {
      const { data } = await API.get(route, {
        params: {
          'order[field]': 'score',
          'order[direction]': 'desc',
          'page[size]': pageSize,
          'page[number]': 1
        }
      })

      this.items = data.data.map(
        (team: TeamDataProps, index: number, teams: TeamDataProps[]) => {
          const _score = parseInt(team.attributes?.score ?? '0')
          const _teamScore = parseInt(teams[0].attributes?.score ?? '1')

          return {
            id: team.attributes.team_id,
            rank: (index + 1).toString(),
            score: I18n.toNumber(_score, { precision: 0 }),
            name: team.attributes.name,
            step: (
              Math.abs(_score) / Math.abs(_teamScore)
            ).toString()
          }
        }
      )
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
    }
  }
}

export default new TopTeamsStore()
