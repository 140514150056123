import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { DownloadButton, Selector, Spinner } from 'Elements'
import Labels from './Labels'
import Paging from './Paging'
import ListItem from './ListItem'
import ListItemSkeleton from './ListItem/ListItemSkeleton'
import Indicator from './Indicator'
import ListStore from './Store'
import * as styles from './styles.module.scss'
import { DefaultContext } from '@giki_zero/javascript/contexts'
import { MODAL_TYPES } from '@giki_zero/javascript/constants'

export const ListType = ListStore.listType

const EmptyMessage = () => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  return (
    <div className={styles.EmptyMessage}>
      {I18n.t('leaderboard.list.empty_message')}
    </div>
  )
}

interface ListProps {
  authenticatedPersonId: string
  route: string
  type: string
  showDownload?: boolean
  updatedAtText?: string
}

const List = observer(
  ({ authenticatedPersonId, route, type, showDownload = false, updatedAtText = '' }: ListProps) => {
    const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

    const {
      applicationStore: { modalStore }
    } = React.useContext(DefaultContext)

    const sortOptions = [
      {
        id: 'score',
        label: I18n.t('leaderboard.list.sorting.score'),
        value: 0
      },
      {
        id: 'change',
        label: I18n.t('leaderboard.list.sorting.change'),
        value: 1
      }
    ]

    const _init = () => {
      ListStore.init({ route, type, authenticatedPersonId })
    }

    const [_sortedBy, _setSortedBy] = useState(sortOptions[0].value)

    const _onSortChanged = (e) => {
      _setSortedBy(e.value)
      void ListStore.fetchItems(
        ListStore.paging.current,
        sortOptions[e.value].id
      )
    }

    const _onDownloadError = () => {
      modalStore.createAlert({
        message: I18n.t('leaderboard.list.download.error'),
        type: 'alert'
      })
    }

    const _onDownloadSuccess = async () => {
      modalStore.openModal({
        category: MODAL_TYPES.LEADERBOARD_ENQUEUE_SUCCESS
      })
    }

    useEffect(() => {
      _init()
    }, [])

    if (!ListStore.loaded) {
      return (
        <div className={styles.Container}>
          <Spinner centered />
        </div>
      )
    }

    return (
      <>
        <section className={styles.ListFilters}>
          <div className={styles.ListFilters__filter}>
            <label htmlFor="sorting-select">
              {I18n.t('leaderboard.list.sorting.sort_by')}
            </label>
            <Selector
              isSmall
              onChange={_onSortChanged}
              options={sortOptions}
              className={styles.ListFilters__selector}
              selectedIndex={_sortedBy}
            />
          </div>
          {showDownload && (
            <div className={styles.ListFilters__downloadContainer}>
              <span className={styles.ListFilters__label}>
                {I18n.t('leaderboard.list.download.label')}
              </span>
              <DownloadButton
                onError={_onDownloadError}
                onSuccess={_onDownloadSuccess}
                label={styles.ListFilters__download}
                id="leaderboard"
                path={ListStore.downloadRoute}
              />
            </div>
          )}
          <p className={styles.ListFilters__subtext}>{updatedAtText}</p>
        </section>
        <div className={styles.Container}>
          {ListStore.items.length === 0 && (
            <>
              {ListStore.loading && <Spinner centered />}
              {!ListStore.loading && <EmptyMessage />}
            </>
          )}

          {ListStore.items.length > 0 && (
            <>
              <Labels sortedBy={sortOptions[_sortedBy].id} />

              {ListStore.loading && (
                <div className={styles.List}>
                  <Spinner absolute centered />
                  <ul>
                    {Array(ListStore.pageSize)
                      .fill('')
                      .map((item, index) => (
                        <ListItemSkeleton key={index} />
                      ))}
                  </ul>
                </div>
              )}

              {!ListStore.loading && (
                <div
                  className={classnames(styles.List, {
                    [styles['List--indicatortop']]:
                      ListStore.indicatorsTop.length > 0,
                    [styles['List--indicatorbottom']]:
                      ListStore.indicatorsBottom.length > 0
                  })}>
                  {ListStore.indicatorsTop && (
                    <Indicator items={ListStore.indicatorsTop} position="top" />
                  )}
                  <ul>
                    {ListStore.items.map((item, index) => (
                      <ListItem key={index} {...item} />
                    ))}
                  </ul>
                  {ListStore.indicatorsBottom && (
                    <Indicator
                      items={ListStore.indicatorsBottom}
                      position="bottom"
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {ListStore.paging && <Paging {...ListStore.paging} />}
      </>
    )
  }
)

export default List
