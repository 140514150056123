import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import ThickArrowIcon from 'Images/icons/ThickArrowIcon'
import * as styles from './styles.module.scss'

interface ChangeProps {
  value: number
}

const Change = ({ value }: ChangeProps) => {
  const { I18n } = useLocalI18n()

  return (
    <div
      className={classnames(styles.Change, {
        [styles['Change--neutral']]: value === 0,
        [styles['Change--positive']]: value > 0,
        [styles['Change--negative']]: value < 0
      })}>
      <span className={styles.Change__icon}>
        <ThickArrowIcon />
      </span>
      <span className={styles.Change__value}>
        {I18n.toNumber(value, { precision: 0 })}
      </span>
      <span className={styles.Change__unit}>PTS</span>
    </div>
  )
}

export default Change
