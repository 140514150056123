import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { TopItemProps, TopItem } from '../index'
import TopTeamsStore, { ItemProps } from './Store'
import * as styles from './styles.module.scss'

interface TopTeamsProps extends TopItemProps {
  route: string
}

const TopTeams = observer(
  ({
    route,
    title,
    subTitle,
    linkPath,
    linkLabel,
    number = 3,
    emptyMessage
  }: TopTeamsProps) => {
    useEffect(() => {
      void TopTeamsStore.fetchItems({ pageSize: number, route })
    }, [])

    return (
      <TopItem
        className={styles.TopTeams}
        linkPath={linkPath}
        linkLabel={linkLabel}
        title={title}
        subTitle={subTitle}
        loading={TopTeamsStore.loading}
        emptyMessage={TopTeamsStore.items.length === 0 && emptyMessage}>
        {!TopTeamsStore.loading &&
          TopTeamsStore.items.length > 0 &&
          TopTeamsStore.items.map((item: ItemProps) => (
            <div
              className={classnames(
                styles.TopCard,
                styles[`TopCard--${item.rank}`]
              )}
              style={{
                minWidth: '70%',
                maxWidth: '100%',
                width: `calc(${item.step} * 100%${
                  item.rank !== '1' ? ' - 1rem' : ''
                })`
              }}
              key={`top-team-${item.id}`}>
              <div className={styles.TopCard__avatar} />
              <div className={styles.TopCard__content}>
                <div className={styles.TopCard__title}>{item.name}</div>
                <div className={styles.TopCard__points}>{item.score} PTS</div>
              </div>
            </div>
          ))}
      </TopItem>
    )
  }
)

export default TopTeams
