import React from 'react'
import Avatar from '../Avatar'
import { Tag, TagColors, TagProps, Button, ButtonTypes } from 'Elements'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

export interface LinksListAdditionalTag {
  title: string
  tag?: string
  uppercase?: boolean
}

export interface LinksListItemProps {
  title: string
  subTitle?: string
  onClick?: () => void
  link?: string
  linkLabel?: string
  linkIcon?: string | React.ElementType
  secondaryLinks?: Array<{
    onClick?: () => void
    link?: string
    linkLabel?: string
    linkIcon?: string | React.ElementType
  }>
  avatar?: string
  avatarFirstName?: string
  avatarLastName?: string
  tags?: TagProps[]
  additionalTags?: LinksListAdditionalTag[]
  selectable?: boolean
  selected?: boolean
  buttonLinkOnly?: boolean
}

export interface LinksListProps {
  items: LinksListItemProps[]
  wide?: boolean
}

const LinksList = React.forwardRef(
  ({ items, wide }: LinksListProps, ref: React.RefObject<any>) => {
    return (
      <div
        className={classnames(styles.LinksList, {
          [styles['LinksList--wide']]: wide
        })}>
        <ul className={styles.LinksList__list} ref={ref}>
          {items.map((item, index) => (
            <li
              key={`links-list-item-${index}`}
              className={classnames(styles.LinksList__item, {
                [styles['LinksList__item--selected']]:
                  item.selectable && item.selected
              })}>
              <div className={styles.LinksList__item__inner}>
                {!item.buttonLinkOnly && (
                  <Button
                    onClick={item.onClick}
                    href={item.link}
                    ariaLabel={item.linkLabel}
                    variant={ButtonTypes.BLANK}
                    className={styles.LinksList__item__bgLink}
                  />
                )}
                {(item.avatar ||
                  item.avatarFirstName ||
                  item.avatarLastName) && (
                    <div className={styles.LinksList__item__avatar}>
                      <Avatar
                        firstName={item.avatarFirstName}
                        lastName={item.avatarLastName}
                        imageSrc={item.avatar}
                        size="small"
                      />
                    </div>
                  )}
                <p className={styles.LinksList__item__content}>
                  <span className={styles.LinksList__item__title}>
                    {item.title}
                  </span>
                  {item.tags && item.tags.length > 0 && (
                    <span className={styles.LinksList__item__tags}>
                      {item.tags.map((tag) => (
                        <Tag
                          text={tag.text}
                          color={tag.color}
                          Icon={tag.Icon}
                          key={tag.text}
                          uppercase={tag.uppercase}
                        />
                      ))}
                    </span>
                  )}
                  {item.subTitle && (
                    <span className={styles.LinksList__item__subTitle}>
                      {item.subTitle}
                    </span>
                  )}
                </p>
                {item.additionalTags?.length > 0 && (
                  <ul className={styles.LinksList__item__additional__tags}>
                    {item.additionalTags.map((additionalTag) => {
                      return (
                        <li
                          className={styles.LinksList__item__additional__tag}
                          key={additionalTag.title}>
                          <h4>{additionalTag.title}</h4>
                          {additionalTag.tag && (
                            <div
                              className={
                                styles.LinksList__item__additional__subtag
                              }>
                              <Tag
                                color={TagColors.BRAND_REVERSE}
                                text={additionalTag.tag}
                                uppercase={additionalTag.uppercase}
                              />
                            </div>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                )}
                {!item.selectable && (
                  <div className={styles.LinksList__item__buttons}>
                    <Button
                      onClick={item.onClick}
                      href={item.link}
                      label={item.linkIcon ? null : item.linkLabel}
                      ariaLabel={item.linkLabel}
                      icon={item.linkIcon}
                      variant={
                        item.linkIcon ? ButtonTypes.DEFAULT : ButtonTypes.BLANK
                      }
                      small={!item.linkIcon}
                      large={!!item.linkIcon}
                      className={styles.LinksList__item__link}
                    />
                    {item.secondaryLinks?.map((secondaryLink, index) => {
                      return (
                        (secondaryLink.link || secondaryLink.onClick) &&
                        secondaryLink.linkLabel && (
                          <Button
                            key={`secondary-link-${index}`}
                            onClick={secondaryLink.onClick}
                            href={secondaryLink.link}
                            label={
                              secondaryLink.linkIcon
                                ? null
                                : secondaryLink.linkLabel
                            }
                            ariaLabel={secondaryLink.linkLabel}
                            icon={secondaryLink.linkIcon}
                            variant={
                              secondaryLink.linkIcon
                                ? ButtonTypes.DEFAULT
                                : ButtonTypes.BLANK
                            }
                            small={!secondaryLink.linkIcon}
                            large={!!secondaryLink.linkIcon}
                            className={styles.LinksList__item__link}
                          />
                        )
                      )
                    })}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
)

LinksList.displayName = 'LinksList'

export default LinksList
