import { LinkContext } from 'clarity'
import React from 'react'
import { ContainerProps } from 'Containers'

interface LinkContextProviderProps extends Partial<ContainerProps> {
  children: React.ReactNode
}

export const LinkContextProvider = ({
  children,
  authenticated,
  currentPersonId
}: LinkContextProviderProps) => {
  const _linkContext = {
    homePageLinkUrl: '/',
    footprintPageLinkUrl: `/people/${currentPersonId}/footprints`,
    footprintDetailPageLinkUrl: `/people/${currentPersonId}/footprints/{{param}}/new`,
    stepsPageLinkUrl: authenticated
      ? `/people/${currentPersonId}/actions`
      : undefined,
    stepsDoneLinkUrl: `/people/${currentPersonId}/actions?state=complete`,
    stepsExistingLinkUrl: `/people/${currentPersonId}/actions?state=existing`,
    stepDetailPageLinkUrl: authenticated
      ? `/people/${currentPersonId}/actions/{{param}}`
      : '/actions/{{param}}',
    todoListPageLinkUrl: `/people/${currentPersonId}/todo/#{{param}}`,
    challengeLeaderboardLinkUrl:
      '/organizations/{{param}}/leaderboard/challenge',
    leaderboardLinkUrl: '/organizations/{{param}}/leaderboard',
    teamLeaderboardLinkUrl: '/teams/{{param}}/leaderboard',
    dashboardPageLinkUrl: `/people/${currentPersonId}/dashboard`,
    insightsPageLinkUrl: `/people/${currentPersonId}/footprints/insights`,
    householdFootprintPageLinkUrl: `/people/${currentPersonId}/footprints/household`,
    goalsPageLinkUrl: `/people/${currentPersonId}/goals`
  }

  return (
    <LinkContext.Provider value={_linkContext}>{children}</LinkContext.Provider>
  )
}
