import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface LabelsProps {
  sortedBy: string
}

const Labels = ({ sortedBy }: LabelsProps) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  const scoreClassNames = [
    styles.Labels__item,
    styles['Labels__item--score'],
    {
      [styles['Labels__item--active']]: sortedBy === 'score'
    }
  ]

  const changeClassNames = [
    styles.Labels__item,
    styles['Labels__item--change'],
    {
      [styles['Labels__item--active']]: sortedBy === 'change'
    }
  ]

  return (
    <div className={styles.Labels}>
      <span
        className={classnames(
          styles.Labels__item,
          styles['Labels__item--rank']
        )}>
        {I18n.t('leaderboard.list.labels.ranking')}
      </span>
      <span
        className={classnames(
          styles.Labels__item,
          styles['Labels__item--dataitem']
        )}>
        {I18n.t('leaderboard.list.labels.trying')}
      </span>
      <span
        className={classnames(
          styles.Labels__item,
          styles['Labels__item--dataitem']
        )}>
        {I18n.t('leaderboard.list.labels.completed')}
      </span>
      <span className={classnames(scoreClassNames)}>
        <span>{I18n.t('leaderboard.list.labels.score')}</span>
      </span>
      <span className={classnames(changeClassNames)}>
        <span>{I18n.t('leaderboard.list.labels.changes')}</span>
        <span className={classnames(styles['Labels__item--changesub'])}>
          {I18n.t('leaderboard.list.labels.changes_sub')}
        </span>
      </span>
    </div>
  )
}

export default Labels
