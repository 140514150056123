import { escape } from './regexp'

export const pathIsActive = (path: string, inclusive: boolean | string = false) => {
  const _origin = path.includes('http') ? '' : window.location.origin

  const _pageUrl = new URL(
    `${window.location.origin}${window.location.pathname}`
  )

  // Remove the query string if one has been supplied.
  const [_path] = path.split('?')

  const _pathUrl = new URL(`${_origin}${_path}`)

  if (inclusive) {
    const _testPath = typeof inclusive === 'string' ? inclusive : _pathUrl.href

    return new RegExp(`${escape(_testPath)}.*`, 'ig').test(_pageUrl.href)
  }

  return _pathUrl.href === _pageUrl.href
}
