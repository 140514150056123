import React from 'react'
import classnames from 'classnames'
import { Spinner } from 'Elements'
import ArrowIcon from 'Images/icons/ArrowIcon'
import * as styles from './styles.module.scss'
import Podium from './Podium'
import TopSteps from './TopSteps'
import TopTeams from './TopTeams'
import { safelyNavigate } from 'Utilities/navigation'

export { Podium, TopSteps, TopTeams }

export interface TopItemProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  title: string
  subTitle: string
  linkPath?: string
  linkLabel?: string
  number?: number
  emptyMessage?: string
  loading?: boolean
}

export const TopItem = ({
  linkPath,
  linkLabel,
  title,
  subTitle,
  emptyMessage,
  loading = false,
  children,
  className
}: TopItemProps) => {
  const _onClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    
    safelyNavigate(linkPath)
  }

  return (
    <div className={classnames(styles.TopItem, className)}>
      <h2 className={classnames('h3', styles.TopItem__title)}>{title}</h2>
      <p className={styles.TopItem__subtitle}>{subTitle}</p>
      {loading && <Spinner centered />}

      {!loading && emptyMessage && (
        <div className={styles.TopItem__message}>{emptyMessage}</div>
      )}

      <div>{children}</div>

      {linkPath && linkLabel && (
        <div className={styles.TopItem__links}>
          <a
            className={styles.TopItem__links__item}
            href={linkPath}
            onClick={(evt) => _onClick(evt)}>
            {linkLabel}
            <ArrowIcon />
          </a>
        </div>
      )}
    </div>
  )
}

const TopContainer = ({ children }) => {
  return <div className={styles.TopContainer}>{children}</div>
}

export default TopContainer
