import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import classnames from 'classnames'
import { Button } from 'Elements'
import * as styles from './styles.module.scss'

const STORAGE_SECURE =
  typeof window !== 'undefined' && window.location.protocol === 'http:'
    ? ''
    : 'secure;'

const STORAGE_DOMAIN =
  typeof window !== 'undefined' &&
  (['localhost', 'dev.local', 'erikas-macbook-air.local'].includes(
    window.location.hostname
  ) ||
    window.location.hostname.includes('.amplifyapp.com'))
    ? ''
    : 'domain=.giki.earth;'

export const COOKIES_STORAGE_KEY = '_giki_cookie_notification'

const CookieNotification = () => {
  const { I18n } = useLocalI18n('elements/CookieNotification/Lang')

  const _privacyTextRef = useRef(null)
  const [_isVisible, _setIsVisible] = useState(false)

  const _handleClick = () => {
    if (typeof document === 'undefined') return

    const _cookieDate = new Date()
    _cookieDate.setFullYear(_cookieDate.getFullYear() + 1)

    document.cookie = `${COOKIES_STORAGE_KEY}=1;path=/;SameSite=Lax;expires=${_cookieDate.toUTCString()};${STORAGE_DOMAIN}${STORAGE_SECURE}`

    _setIsVisible(false)
  }

  const _docClick = (evt: MouseEvent) => {
    if (
      _privacyTextRef.current?.contains(evt.target) &&
      (evt.target as HTMLElement).tagName === 'A'
    ) {
      _handleClick()
    }
  }

  useEffect(() => {
    document.addEventListener('click', _docClick)

    return () => {
      document.removeEventListener('click', _docClick)
    }
  })

  useEffect(() => {
    const _previouslyViewed =
      typeof document !== 'undefined' &&
      document.cookie.includes(COOKIES_STORAGE_KEY)

    if (_previouslyViewed) return

    _setIsVisible(true)
  }, [])

  if (!_isVisible) return null

  return (
    <div
      className={styles.CookieNotification}
      aria-labelledby="privacy_title"
      aria-describedby="privacy_text"
      role="dialog"
      tabIndex={1}>
      <p
        id="privacy_title"
        className={classnames('h4', styles.CookieNotification__title)}>
        {I18n.t('cookie_notification.title')}
      </p>
      <p
        id="privacy_text"
        className={styles.CookieNotification__text}
        ref={_privacyTextRef}>
        {ReactHtmlParser(I18n.t('cookie_notification.text'))}
      </p>
      <Button
        onClick={_handleClick}
        label={I18n.t('cookie_notification.button')}
        tabIndex={1}
      />
    </div>
  )
}

export default CookieNotification
