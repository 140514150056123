import { action, observable } from 'mobx'
import { API } from 'API'
import { standardizeError } from 'Utilities/errors'
import { getCommitmentFromAction } from 'Utilities/actions'
import {
  ACTION_STATES,
  SerializedAction,
  SerializedCommitment
} from 'Constants'

class TopStepsStore {
  @observable public data = {
    [ACTION_STATES.PENDING]: {
      initialized: false,
      loading: true,
      pageSize: 3,
      route: '',
      actions: [],
      commitments: []
    },
    [ACTION_STATES.SUCCESSFUL]: {
      initialized: false,
      loading: true,
      pageSize: 3,
      route: '',
      actions: [],
      commitments: []
    }
  }

  @action
  init = async ({ state, pageSize, route }) => {
    if (!state) return
    if (pageSize) this.data[state].pageSize = pageSize
    if (route) this.data[state].route = route
    await this.fetchActions(state)
    this.data[state].initialized = true
  }

  @action
  fetchActions = async (state: string) => {
    this.data[state].actions.clear()
    this.data[state].commitments.clear()
    this.data[state].loading = true

    try {
      const { data } = await API.get(this.data[state].route, {
        params: {
          state,
          'page[size]': this.data[state].pageSize,
          'page[number]': 1
        }
      })

      if (data.data) {
        data.data.forEach((item: SerializedAction) => {
          this.data[state].actions.push(item)
        })
      }

      if (data.included) {
        data.included
          .filter((item: SerializedCommitment) => item.type === 'commitment')
          .forEach((item: SerializedCommitment) => {
            this.data[state].commitments.push(item)
          })
      }

      this.data[state].loading = false
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  reFetchActions = () => {
    Object.keys(this.data).forEach((key) => {
      if (this.data[key].initialized) {
        void this.fetchActions(key)
      }
    })
  }

  findAction = ({ actionId, state }) => {
    const action = this.data[state].actions.find(
      (item: SerializedAction) => item.id === actionId
    )
    const commitment = getCommitmentFromAction(
      action,
      this.data[state].commitments
    )

    return { action, commitment }
  }

  abandonCommitment = async ({ actionId, state }) => {
    try {
      const { action } = this.findAction({ actionId, state })
      const apiPath = action.relationships.commitments.links.last
      const { data } = await API.patch(apiPath, {
        state: ACTION_STATES.ABANDONED
      })
      this.reFetchActions()
      return { action, data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  createActionSuppression = async ({ actionId, state }) => {
    try {
      const { action } = this.findAction({ actionId, state })
      const apiPath = action.relationships.suppressions.links.create
      const { data } = await API.post(apiPath)
      this.reFetchActions()
      return { action, data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  createPendingCommitment = async ({ actionId, state }) => {
    try {
      const { action } = this.findAction({ actionId, state })
      const apiPath = action.relationships.commitments.links.create
      const { data } = await API.post(apiPath, {
        state: ACTION_STATES.PENDING
      })
      this.reFetchActions()
      return { action, data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  createExistingCommitment = async ({ actionId, state }) => {
    try {
      const { action } = this.findAction({ actionId, state })
      const apiPath = action.relationships.commitments.links.create
      const { data } = await API.post(apiPath, {
        state: ACTION_STATES.EXISTING
      })
      this.reFetchActions()
      return { action, data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  succeedAtCommitment = async ({ actionId, state }) => {
    try {
      const { action } = this.findAction({ actionId, state })
      const apiPath = action.relationships.commitments.links.last
      const { data } = await API.patch(apiPath, {
        state: ACTION_STATES.SUCCESSFUL
      })
      this.reFetchActions()
      return { action, data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }
}

export default new TopStepsStore()
