import I18n from 'i18n-js'
import { action, computed, observable } from 'mobx'
import { API } from 'API'
import { standardizeError } from 'Utilities/errors'
import { MemberDataProps, OrganizationDataProps, PodiumType } from '../../index'

export interface ItemProps {
  id: string
  rank: string
  score: number
  avatar: string
  firstName?: string
  lastName?: string
  name?: string
  step: string
  isCurrent: boolean
}
class PodiumStore {
  @observable public currentId: string
  @observable public loading = true
  @observable public type: string
  @observable items: ItemProps[] = []

  @action
  init = (type, currentId) => {
    this.currentId = currentId
    this.type = type
  }

  @action
  fetchItems = async ({ pageSize, route }) => {
    try {
      const { data } = await API.get(route, {
        params: {
          'order[field]': 'score',
          'order[direction]': 'desc',
          'page[size]': pageSize,
          'page[number]': 1
        }
      })

      this.items = data.data.map(this.mapper)

      if (this.items.length === 3) {
        const _first = this.items.shift()
        this.items.splice(1, 0, _first)
      }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
    }
  }

  @computed
  get mapper() {
    if (this.type === PodiumType.PEOPLE) return this.memberMapper

    if (this.type === PodiumType.ORGANIZATIONS) return this.organizationMapper
  }

  memberMapper = (
    person: MemberDataProps,
    index: number,
    people: MemberDataProps[]
  ) => {
    const _score = parseInt(person.attributes?.score ?? '0')

    return {
      id: person.attributes.person_id,
      rank: (index + 1).toString(),
      rank_change: (index + 1).toString(),
      avatar: person.attributes.avatar,
      score: I18n.toNumber(_score, {
        precision: 0
      }),
      firstName: person.attributes.first_name,
      lastName: `${person.attributes?.last_name?.charAt(0)}.`,
      step: (
        Math.abs(_score) / Math.abs(parseInt(people[0].attributes.score))
      ).toString(),
      isCurrent: person.attributes.person_id === this.currentId
    }
  }

  organizationMapper = (
    organization: OrganizationDataProps,
    index: number,
    organizations: OrganizationDataProps[]
  ) => {
    const _score = parseInt(organization.attributes?.score ?? '0')

    return {
      id: organization.attributes.organization_id,
      rank: (index + 1).toString(),
      avatar: organization.attributes.logo,
      score: I18n.toNumber(_score, {
        precision: 0
      }),
      name: organization.attributes.name,
      step: (
        Math.abs(_score) / Math.abs(parseInt(organizations[0].attributes.score))
      ).toString(),
      isCurrent: organization.attributes.organization_id === this.currentId
    }
  }
}

export default new PodiumStore()
