import List, { ListType } from './List'
import TopContainer, { Podium, TopSteps, TopTeams } from './Top'

interface AttributeProps {
  score: string
  change: number | null
  complete_actions_count: number
  pending_actions_count: number
  rank: number
  rank_change: number
  rank_trying?: number
}

interface MemberAttributeProps extends AttributeProps {
  avatar: string
  first_name: string
  last_name: string
  teams?: string[]
  person_id: string
}

interface MemberDataProps {
  attributes: MemberAttributeProps
}

interface OrganizationAttributeProps extends AttributeProps {
  organization_id: string
  name: string
  logo: string
}

interface OrganizationDataProps {
  attributes: OrganizationAttributeProps
}

interface TeamAttributeProps extends AttributeProps {
  team_id: string
  name: string
  logo: string
}

interface TeamDataProps {
  attributes: TeamAttributeProps
}

const PageType = {
  ALL_ORGANIZATIONS: 'ALL_ORGANIZATIONS',
  ALL_PARTICIPANTS: 'ALL_PARTICIPANTS',
  ALL_TEAMS: 'ALL_TEAMS',
  TOP3: 'TOP3',
  CAMPAIGN: 'CAMPAIGN',
  CAMPAIGN_TEAMS: 'CAMPAIGN_TEAMS'
}

const PodiumType = {
  PEOPLE: 'PEOPLE',
  ORGANIZATIONS: 'ORGANIZATIONS'
}

export {
  AttributeProps,
  MemberDataProps,
  OrganizationDataProps,
  TeamDataProps,
  List,
  ListType,
  PageType,
  PodiumType,
  TopContainer,
  Podium,
  TopSteps,
  TopTeams
}
