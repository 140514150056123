import useLocalI18n from 'Hooks/LocalI18n'
import { action, observable, computed } from 'mobx'
import { API } from 'API'
import { standardizeError } from 'Utilities/errors'
import {
  OrganizationDataProps,
  TeamDataProps,
  MemberDataProps,
  AttributeProps
} from '../index'
import { ListItemProps } from './ListItem'

const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

interface IndicatorProps {
  name: string
  image: string
  onClick: () => void
  page?: number
}

class ListStore {
  processData
  @observable listType = {
    ORGANIZATION: 'ORGANIZATION',
    ORGANIZATION_TEAM: 'ORGANIZATION_TEAM',
    SUPER_GROUP_ORGANIZATION: 'SUPER_GROUP_ORGANIZATION',
    TEAM: 'TEAM',
    CAMPAIGN: 'CAMPAIGN'
  }

  @observable loaded = false
  @observable loading = true
  @observable pageSize = 9
  @observable route = ''
  @observable type = ''
  @observable authenticatedPersonId = ''
  @observable sortedBy = 'score'
  @observable paging = null
  items = observable<ListItemProps>([])
  indicatorsTop = observable<IndicatorProps>([])
  indicatorsBottom = observable<IndicatorProps>([])
  @observable personIndicator = null

  @action
  init = ({ route, type, authenticatedPersonId, sortedBy = 'score' }) => {
    this.reset()

    this.route = route
    this.type = type
    this.authenticatedPersonId = authenticatedPersonId
    this.sortedBy = sortedBy

    void this.fetchItems()
  }

  @action
  reset = () => {
    this.route = ''
    this.type = ''
    this.authenticatedPersonId = ''
    this.loaded = false
    this.loading = true
    this.sortedBy = 'score'
    this.paging = null
    this.items.clear()
    this.indicatorsTop.clear()
    this.indicatorsBottom.clear()
    this.personIndicator = null
  }

  @action
  fetchItems = async (page = null, sortedBy = null) => {
    this.loading = true

    this.sortedBy = sortedBy || this.sortedBy

    try {
      const _time = 1000
      const _t1 = performance.now()

      const { data } = await API.get(this.route, {
        params: {
          'page[size]': this.pageSize,
          'page[number]': page,
          order_by: this.sortedBy
        }
      })

      const _tDif = performance.now() - _t1
      if (_tDif < _time) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, _time - _tDif)
        })
      }

      if (data.meta?.pagination) {
        this.paging = {
          ...data.meta.pagination,
          onClick: this.fetchItems
        }
      } else {
        this.paging = null
      }

      switch (this.type) {
        case this.listType.ORGANIZATION:
          this.processMemberData({
            data,
            page,
            showTeam: true,
            processIndicator: true
          })
          break
        case this.listType.ORGANIZATION_TEAM:
          this.processTeamsData({ data })
          break
        case this.listType.SUPER_GROUP_ORGANIZATION:
          this.processOrganizationsData({ data })
          break
        case this.listType.TEAM:
          this.processMemberData({
            data,
            page,
            processIndicator: true
          })
          break
      }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
      this.loaded = true
    }
  }

  findRank = (attributes: AttributeProps) => {
    switch(this.sortedBy) {
      case 'score':
        return attributes.rank;
      case 'change':
        return attributes.rank_change;
      case 'successful':
        return attributes.rank;
      case 'trying':
        return attributes.rank_trying;
      default:
        return attributes.rank;
    }
  }

  parseSharedData = (attributes: AttributeProps) => {
    const _score = parseInt(attributes?.score ?? '0')
    const _change = attributes?.change ?? 0
    const _rank = this.findRank(attributes)

    return {
      rank: _rank ?? 0,
      score: _score,
      change: _change,
      successfulCommitmentCount: attributes?.complete_actions_count ?? 0,
      pendingCommitmentCount: attributes?.pending_actions_count ?? 0,
      hasHighScore: _rank === 1,
      sortedBy: this.sortedBy
    }
  }

  @action
  processMemberData = ({
    data,
    page,
    showTeam = false,
    processIndicator = false
  }) => {
    this.items.clear()

    if (!data?.data) return

    data.data.forEach((member: MemberDataProps) => {
      const _lastName = member.attributes?.last_name
        ? `${member.attributes?.last_name.charAt(0)}.`
        : ''

      this.items.push({
        ...this.parseSharedData(member.attributes),
        ...{
          avatar: member.attributes?.avatar,
          firstName: member.attributes?.first_name,
          lastName: _lastName,
          name: `${member.attributes?.first_name} ${_lastName}`,
          isCurrent: member.attributes.person_id === this.authenticatedPersonId,
          team: showTeam && member.attributes?.teams?.join(', ')
        }
      })
    })

    if (processIndicator) {
      if (page === null) {
        this.savePersonIndicator({ data })
      } else {
        this.getPersonIndicator({ data })
      }
    }
  }

  @action
  processOrganizationsData = ({ data }) => {
    this.items.clear()

    if (!data?.data) return

    const _organizations = data.meta.own_organizations.map(
      (organization) => organization.organization_id
    )

    data.data.forEach((organization: OrganizationDataProps) => {
      const _isCurrentOrganization = _organizations.includes(
        organization.attributes.organization_id
      )

      this.items.push({
        ...this.parseSharedData(organization.attributes),
        ...{
          avatar: organization.attributes.logo,
          name: organization.attributes.name,
          isCurrentOrganization: _isCurrentOrganization
        }
      })
    })

    this.getOrganizationIndicators({ data })
  }

  @action
  processTeamsData = ({ data }) => {
    this.items.clear()

    if (!data?.data) return

    const _teams = data.meta.own_teams.map((team) => team.team_id)

    data.data.forEach((team: TeamDataProps) => {
      const _isCurrentTeam = _teams.includes(team.attributes.team_id)

      this.items.push({
        ...this.parseSharedData(team.attributes),
        ...{
          avatar: team.attributes.logo,
          name: team.attributes.name,
          isCurrentTeam: _isCurrentTeam
        }
      })
    })

    this.getTeamIndicators({ data })
  }

  @action
  getOrganizationIndicators = ({ data }) => {
    this.indicatorsTop.clear()
    this.indicatorsBottom.clear()

    if (!data.meta?.own_organizations || data.pagination?.last < 2) return

    data.meta.own_organizations.forEach((organization) => {
      let _page = Math.ceil(organization.rank / this.pageSize)

      if (this.sortedByChange) {
        _page = Math.ceil(organization.rank_change / this.pageSize)
      }

      if (_page === data.meta.pagination.current) return

      const _indicator = {
        name: organization.name,
        image: organization.logo,
        onClick: async () => await this.fetchItems(_page)
      }

      if (_page < data.meta.pagination.current) {
        this.indicatorsTop.push(_indicator)
      }

      if (_page > data.meta.pagination.current) {
        this.indicatorsBottom.push(_indicator)
      }
    })
  }

  @action
  getTeamIndicators = ({ data }) => {
    this.indicatorsTop.clear()
    this.indicatorsBottom.clear()

    if (!data.meta?.own_teams || data.pagination?.last < 2) return

    data.meta.own_teams.forEach((team) => {
      let _page = Math.ceil(team.rank / this.pageSize)

      if (this.sortedByChange) {
        _page = Math.ceil(team.rank_change / this.pageSize)
      }

      if (_page === data.meta.pagination.current) return

      const _indicator = {
        name: team.name,
        image: team.logo,
        onClick: async () => await this.fetchItems(_page)
      }

      if (_page < data.meta.pagination.current) {
        this.indicatorsTop.push(_indicator)
      }

      if (_page > data.meta.pagination.current) {
        this.indicatorsBottom.push(_indicator)
      }
    })
  }

  @action
  savePersonIndicator = ({ data }) => {
    this.indicatorsTop.clear()
    this.indicatorsBottom.clear()
    this.personIndicator = null

    const _person = data.data.find(
      (member) => member.attributes.person_id === this.authenticatedPersonId
    )

    if (!_person) return

    const _onClick = async () => {
      let _page = data.meta.pagination.current

      if (this.sortedByChange) {
        _page = Math.ceil(_person.attributes.rank_change / this.pageSize)
      }

      await this.fetchItems(_page)
    }

    this.personIndicator = {
      name: I18n.t('leaderboard.list.you'),
      image: _person.attributes?.avatar,
      onClick: _onClick,
      page: data.meta.pagination.current,
      rank: _person.attributes.rank,
      rank_change: _person.attributes.rank_change
    }
  }

  @action
  getPersonIndicator = ({ data }) => {
    this.indicatorsTop.clear()
    this.indicatorsBottom.clear()

    if (!this.personIndicator) return

    if (this.sortedByScore) {
      if (this.personIndicator.page < data.meta.pagination.current) {
        this.indicatorsTop.push(this.personIndicator)
      }

      if (this.personIndicator.page > data.meta.pagination.current) {
        this.indicatorsBottom.push(this.personIndicator)
      }
    } else {
      if (
        this.personIndicator.rank_change < data.data[0].attributes.rank_change
      ) {
        this.indicatorsTop.push(this.personIndicator)
      }

      if (
        this.personIndicator.rank_change >
        data.data[data.data.length - 1].attributes.rank_change
      ) {
        this.indicatorsBottom.push(this.personIndicator)
      }
    }
  }

  @computed
  get downloadRoute() {
    return this.route + '/enqueue-download'
  }

  @computed
  get stepsDownloadRoute() {
    return this.route + '/enqueue-steps-download'
  }

  @computed
  get sortedByChange() {
    return this.sortedBy === 'change'
  }

  @computed
  get sortedByScore() {
    return this.sortedBy === 'score'
  }

  @computed
  get sortedByTrying() {
    return this.sortedBy === 'trying'
  }

  @computed
  get sortedBySuccessful() {
    return this.sortedBy === 'successful'
  }
}

export default new ListStore()
