import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

const ListItemSkeleton = () => (
  <li className={classnames(styles.ListItem, styles.Skeleton)}>
    <div className={styles.ListItem__rank}>
      <span className={styles.Skeleton__rank} />
    </div>
    <div className={styles.ListItem__avatar}>
      <div className={styles.ListItem__avatar__image}>
        <span className={styles.Skeleton__avatar} />
      </div>
    </div>
    <div className={styles.ListItem__namegroup}>
      <div className={styles.ListItem__name}>
        <span className={styles.Skeleton__name} />
      </div>
      <div className={styles.ListItem__dataitemgroup}>
        <div className={styles.ListItem__dataitem}>
          <span className={styles.Skeleton__dataitem} />
        </div>
        <div className={styles.ListItem__dataitem}>
          <span className={styles.Skeleton__dataitem} />
        </div>
      </div>
    </div>
    <div className={styles.ListItem__scoregroup}>
      <div className={styles.ListItem__score}>
        <span className={styles.Skeleton__score} />
      </div>
      <div className={styles.ListItem__change}>
        <span className={styles.Skeleton__change} />
      </div>
    </div>
  </li>
)

export default ListItemSkeleton
