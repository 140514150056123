import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import ExpandIcon from 'Images/icons/ExpandIcon'
import * as styles from './styles.module.scss'

interface PagingItemProps {
  current: number
  onClick: (page: number) => void
  pageSize?: number
  value?: number
}

const PagingItem = ({ current, onClick, value }) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  const _onClick = () => {
    onClick(value)
  }

  return (
    <li
      className={classnames(styles.Paging__item, {
        [styles['Paging__item--current']]: current === value
      })}>
      <button
        aria-label={I18n.t('leaderboard.list.paging.go_to_page', {
          page: value.toString()
        })}
        onClick={_onClick}
        className={styles.Paging__item__button}>
        {value}
      </button>
    </li>
  )
}

const Ellipsis = () => <li className={styles.Paging__ellipsis}>&hellip;</li>

export interface PagingProps extends PagingItemProps {
  last: number
}

const Paging = ({ current, onClick, last }: PagingProps) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  const _items = new Array(last).fill('')

  if (last < 2) return null

  return (
    <ul className={styles.Paging}>
      <li className={styles.Paging__item}>
        <button
          aria-label={I18n.t('leaderboard.list.paging.previous_page')}
          className={classnames(
            styles.Paging__item__button,
            styles['Paging__item__button--previous']
          )}
          disabled={current === 1}
          onClick={() => onClick(current - 1)}>
          <ExpandIcon />
        </button>
      </li>
      {_items.map((item, index) => {
        const _pageNumber = index + 1
        const _show =
          last < 7 ||
          _pageNumber === 1 ||
          _pageNumber === last ||
          _pageNumber === current ||
          (current < 4 && _pageNumber < 5) ||
          (current > last - 3 && _pageNumber > last - 4) ||
          _pageNumber === current - 1 ||
          _pageNumber === current + 1

        const _preEllipsis =
          last > 6 &&
          ((_pageNumber === current - 1 && current > 3 && current < last - 2) ||
            (_pageNumber === last && current < 4 && current < last - 4))

        const _postEllipsis =
          last > 6 &&
          ((_pageNumber === current + 1 && current > 3 && current < last - 2) ||
            (_pageNumber === 1 && current > last - 3))

        if (!_show) return null

        return (
          <React.Fragment key={index}>
            {_preEllipsis && <Ellipsis />}
            <PagingItem
              current={current}
              onClick={onClick}
              value={_pageNumber}
            />
            {_postEllipsis && <Ellipsis />}
          </React.Fragment>
        )
      })}
      <li className={styles.Paging__item}>
        <button
          aria-label={I18n.t('leaderboard.list.paging.next_page')}
          className={classnames(
            styles.Paging__item__button,
            styles['Paging__item__button--next']
          )}
          disabled={current === last}
          onClick={() => onClick(current + 1)}>
          <ExpandIcon />
        </button>
      </li>
    </ul>
  )
}

export default Paging
