import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { SerializedAction } from 'Constants'
import { Button } from 'Elements'
import { safelyNavigate } from 'Utilities/navigation'
import TopStepsStore from './Store'
import * as styles from './styles.module.scss'

interface StepItemProps {
  item: SerializedAction
  index: number
  state: string
}

const StepItem = observer(({ item, index, state }: StepItemProps) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/Top/Lang')

  const _trackLearnMoreButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    const { action } = TopStepsStore.findAction({
      actionId: item.id,
      state
    })

    safelyNavigate(action.links.html_summary)
  }

  return (
    <div
      className={classnames(styles.TopCard, styles[`TopCard--${index + 1}`])}>
      <div className={styles.TopCard__avatar} />
      <div className={styles.TopCard__content}>
        <div className={styles.TopCard__title}>
          <a className={styles.Step__title} href={item.links.html_summary}>
            {item.attributes.summary}
          </a>
        </div>
        <div className={styles.Step__buttons}>
          <Button
            ariaLabel={I18n.t('leaderboard.top.learn_more.aria_label', {
              name: item.attributes.summary
            })}
            label={I18n.t('leaderboard.top.learn_more.label')}
            onClick={_trackLearnMoreButton}
            small
          />
        </div>
      </div>
    </div>
  )
})

export default StepItem
