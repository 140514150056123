import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import useLocalI18n from 'Hooks/LocalI18n'
import { Spinner } from 'Elements'
import styles from './styles.module.scss'

interface ContactDetailsProps {
  disabled?: boolean
  email: string
  loading?: boolean
  onUpdate: ({ email }) => void
}

const ContactDetails = observer(
  ({ disabled, email = '', loading, onUpdate }: ContactDetailsProps) => {
    const { I18n } = useLocalI18n('molecules/Settings/Lang')

    const [_email, _setEmail] = useState(email)

    const [_hasChanges, _setHasChanges] = useState(false)

    const _onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      _setEmail(e.target.value)

      if (!_hasChanges) _setHasChanges(true)
    }

    const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onUpdate({ email: _email })

      _setHasChanges(false)
    }

    useEffect(() => {
      _setEmail(email)
    }, [email])

    return (
      <form className={styles.ContactDetails} onSubmit={_onSubmit}>
        {loading && <Spinner absolute />}
        {disabled && (
          <p className={styles.ContactDetails__disabled}>
            {I18n.t('settings.contact_details.disabled')}
          </p>
        )}
        <fieldset className="FormRow FormRow--bs">
          <div className="text-field__wrapper FormRow--column">
            <label htmlFor="email">
              {I18n.t('settings.contact_details.fields.email')}
            </label>
            <input
              autoComplete="email"
              className="text-field text-field--constrained"
              disabled={disabled || loading}
              id="email"
              onChange={_onEmailChange}
              type="email"
              value={_email}
            />
          </div>
        </fieldset>
        <fieldset className="FormRow FormRow--inline-center">
          <button
            className={classnames(styles.Button, styles['Button--color-brand'])}
            disabled={disabled || loading || !_hasChanges}
            type="submit">
            {I18n.t('settings.common.save')}
          </button>
        </fieldset>
      </form>
    )
  }
)

export default ContactDetails
